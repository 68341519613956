import React, {useState, useEffect, useContext, useCallback} from "react";
import styled from "styled-components";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from "date-fns/locale/es"
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select'
import {DataContext} from "../../context/ContextData"
import { Input } from 'semantic-ui-react'
import moment from 'moment'
import { Spinner } from "react-bootstrap";
registerLocale("es", es)

const ModalBody = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [turnos, setTurnos] = useState([]);
  const [horariosDisponibles, setHorariosDisponibles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    fecha, setFecha,
    hora, setHora,
    nombre, setNombre,
    barbero, setBarbero,
    servicio,
    celular, setCelular,
    email, setEmail,
    aclaracion, setAclaracion
  } = useContext(DataContext);

  const allOptions = [
    {value: '10:00', label: '10:00'},
    {value: '10:30', label: '10:30'},
    {value: '11:00', label: '11:00'},
    {value: '11:30', label: '11:30'},
    {value: '12:00', label: '12:00'},
    {value: '12:30', label: '12:30'},
    {value: '13:00', label: '13:00'},
    {value: '13:30', label: '13:30'},
    {value: '14:00', label: '14:00'},
    {value: '14:30', label: '14:30'},
    {value: '15:00', label: '15:00'},
    {value: '15:30', label: '15:30'},
    {value: '16:00', label: '16:00'},
    {value: '16:30', label: '16:30'},
    {value: '17:00', label: '17:00'},
    {value: '17:30', label: '17:30'},
    {value: '18:00', label: '18:00'},
    {value: '18:30', label: '18:30'}
  ];

  const barberoptions = [
    {value: 'Fabricio', label: 'Fabricio'},
    {value: 'Santiago', label: 'Santiago'}
  ];

  const fetchTurnos = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch("https://adiosnonino.com/phpindex.php");
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setTurnos(data);
    } catch (error) {
      console.error('Failed to fetch turnos:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTurnos();
  }, [fetchTurnos]);
  
  useEffect(() => {
    if (selectedDate) {
      setFecha(moment(selectedDate).format('YYYY-MM-DD'));
    }
  }, [selectedDate, setFecha]);
  
  useEffect(() => {
    if (selectedDate && barbero) {
      actualizarHorariosDisponibles();
    }
  }, [selectedDate, barbero, turnos, servicio]);

  const getDuracionServicio = (tipoServicio) => {
    return tipoServicio === 'Corte y Afeitado' ? 60 : 30;
  };
  
  const actualizarHorariosDisponibles = useCallback(() => {
    if (!selectedDate || !barbero) return;
  
    const fechaActual = moment(selectedDate).format('YYYY-MM-DD');
    const diaSemana = moment(selectedDate).day();
  
    let horariosFiltrados = allOptions;
  
    // Filtrar los horarios si es martes (ya está implementado)
    if (diaSemana === 2) {
      horariosFiltrados = horariosFiltrados.filter(hora => {
        const horaNum = parseInt(hora.value.replace(':', ''), 10);
        return horaNum >= 1400 && horaNum <= 1830;
      });
    }
  
    // Aquí está el nuevo filtro para controlar el último turno según el servicio
    if (servicio === 'Corte y Afeitado') {
      horariosFiltrados = horariosFiltrados.filter(hora => {
        const horaNum = parseInt(hora.value.replace(':', ''), 10);
        return horaNum <= 1800; // Limitar el último turno a las 18:00
      });
    } else {
      horariosFiltrados = horariosFiltrados.filter(hora => {
        const horaNum = parseInt(hora.value.replace(':', ''), 10);
        return horaNum <= 1830; // Para otros servicios, permitir hasta las 18:30
      });
    }
  
    const turnosFiltrados = turnos.filter(turno =>
      turno.barbero === barbero && turno.fecha === fechaActual
    );
  
    let horariosDisponibles = [...horariosFiltrados];
    const duracionServicioActual = getDuracionServicio(servicio);
  
    turnosFiltrados.forEach(turno => {
      const turnoHoraInicio = moment(turno.hora, 'HH:mm');
      const turnoDuracion = getDuracionServicio(turno.servicio);
      const turnoHoraFin = turnoHoraInicio.clone().add(turnoDuracion, 'minutes');
  
      horariosDisponibles = horariosDisponibles.filter(hora => {
        const horaOpcion = moment(hora.value, 'HH:mm');
        const horaOpcionFin = horaOpcion.clone().add(duracionServicioActual, 'minutes');
  
        // Excluir horarios que se solapan con el turno actual
        return !(horaOpcion.isBefore(turnoHoraFin) && horaOpcionFin.isAfter(turnoHoraInicio));
      });
    });
  
    setHorariosDisponibles(horariosDisponibles);
  }, [selectedDate, barbero, turnos, servicio, allOptions]);
  
  if (isLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <BodyContainer>
      <div className="columnas cold">
        <div><h2>Resumen</h2></div>
        <div>
          <h6>Servicio</h6>
          <span>{servicio || '-'}</span>
        </div>
        <div>
          <h6>Barbero</h6>
          <span>{barbero || '-'}</span>
        </div>
        <div>
          <h6>Fecha</h6>
          <span>{fecha || '-'}</span>
        </div>
        <div>
          <h6>Horario</h6>
          <span>{hora || '-'}</span>
        </div>
        <div>
          <h6>Nombre</h6>
          <span>{nombre || '-'}</span>
        </div>
        <div>
          <h6>Teléfono</h6>
          <span>{celular || '-'}</span>
        </div>
        <div>
          <h6>Email</h6>
          <span>{email || '-'}</span>
        </div>
        <div>
          <h6>Aclaración</h6>
          <span className="text-break">{aclaracion || '-'}</span>
        </div>
      </div>
      <div className="columnas">
        <div><h2></h2></div>
        <Select
          className="sarasa"
          placeholder="Barbero"
          options={barberoptions}
          onChange={val => {
            setBarbero(val.value);
            setSelectedDate(null); // Opcional: resetear la fecha si se cambia el barbero
            setFecha('');
            setHora('');
            setHorariosDisponibles([]);
          }}
        />
        <DatePicker
          locale="es"
          placeholderText="Fecha"
          className="sarasa sarasapicker"
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          dateFormat='yyyy-MM-dd'
          filterDate={date => date.getDay() !== 0 && date.getDay() !== 1}
          disabled={!barbero} // Deshabilitar si no se ha seleccionado un barbero
        />
        <Select
          className={`sarasa ${barbero && fecha ? "" : "invisible"}`}
          placeholder="Horario"
          onChange={val => setHora(val.value)}
          options={horariosDisponibles}
          isDisabled={!barbero || !fecha} // Opcional: deshabilitar si no se cumple la condición
        />
        <Input
          className={`sarasa ${barbero && fecha && hora ? "" : "invisible"}`}
          placeholder="Nombre y Apellido"
          onChange={e => setNombre(e.target.value)}
          disabled={!barbero || !fecha || !hora} // Opcional: deshabilitar si no se cumple la condición
        />
        <Input
          className={`sarasa ${barbero && fecha && hora && nombre ? "" : "invisible"}`}
          placeholder="Teléfono"
          onChange={e => setCelular(e.target.value)}
          disabled={!barbero || !fecha || !hora || !nombre} // Opcional: deshabilitar si no se cumple la condición
        />
        <Input
          className={`sarasa ${barbero && fecha && hora && nombre && celular ? "" : "invisible"}`}
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
          type="email"
          disabled={!barbero || !fecha || !hora || !nombre || !celular} // Opcional: deshabilitar si no se cumple la condición
        />
        <Input
          className={`sarasa ${barbero && fecha && hora && nombre && celular && email ? "" : "invisible"}`}
          placeholder="Aclaración"
          onChange={e => setAclaracion(e.target.value)}
          disabled={!barbero || !fecha || !hora || !nombre || !celular || !email} // Opcional: deshabilitar si no se cumple la condición
        />
      </div>
    </BodyContainer>
  );
};

export default ModalBody;

const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .columnas {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .columnas h6 {
    font-weight: bold;
    font-size: 1.3rem;
  }

  .columnas h2 {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .columnas span {
    font-size: 1rem;
  }

  .sarasapicker {
    margin: .5rem 0;
  }

  .sarasa {
    padding: .5rem;
    border-radius: .3rem;
    border-color: #D3D3D3;
    box-shadow: none;
    width: 100%;
    text-align: center;
  }

  .invisible {
    visibility: hidden;
  }

  .sarasa:focus {
    outline-color: #719ECE;
  }

  .react-datepicker__input-container input[disabled] {
    background-color: #f0f0f0;
    cursor: not-allowed;
    }
`;